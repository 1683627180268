import React from 'react';
import Header from '../../components/Header';
import Divider from '../../components/Divider';
import { motion } from 'framer-motion';
import './about.css';

const container = {
    initial: {
        opacity: 0,
    },
    enter: {
        opacity: 1,
        transition: {
          opacity:{duration:1, type:"spring"},
          when: "beforeChildren",
          staggerChildren: 0.3,
        },
    }
};

const variantsContent = {
  initial:{
    y:20,
    opacity:0,
  },
  enter: {
    y:0,
    opacity:1,
    transition: {
      x: { type: "spring", stiffness: 50 },
      opacity: { duration: 1.2 }
    },
  }
};
const About: React.FC = () => {
  return (
    <div id="page-about">
      <Divider text="SOBRE NÓS"/>
      <motion.div className="container" variants={container} initial="initial" animate="enter">
        <motion.div className="d-flex flex-row justify-content-center" variants={variantsContent}>
          <div className="col-sm-12 col-md-10 col-lg-10">
            <h1 className="h1-header text-center">TEIA ANANSI</h1>
          </div>
        </motion.div>
        <motion.div className="d-flex flex-row justify-content-center" variants={variantsContent}>
          <div className="col-sm-12 col-md-10 col-lg-10 ">            
            <article>
              <p>
                A Coletiva TEIA é originária da frente feminista negra Bloco das Pretas, que surgiu dentro do Coletivo de Estudantes Negrxs CEN-BH.
                 O bloco aparece como uma demanda de espaço para a expressão e o protagonismo da resistência da mulher negra, 
                diante de diversas opressões dentro da estrutura capitalista. Através de uma abordagem interseccional e, principalmente, 
                de intervenções artísticas e sociais, a frente traça uma luta pela descolonização do pensamento e da prática eurocêntrica.
              </p>

              <p>
                A partir de um projeto social, dentro de um Socioeducativo, surge a Coletiva TEIA. Uma coletiva de mulheres
                negras periféricas, oriundas de contextos de vulnerabilidade socioeconômica. Somos uma organização social em 
                rede com outras coletivas e organizações para práticas em prol do acesso aos Direitos Humanos, por mulheres e adolescentes,
                principalmente negras, grupos em que tais direitos são mais inacessíveis.
              </p>
            </article>
          </div>
        </motion.div>
        
        <motion.div className="d-flex flex-row justify-content-center" variants={variantsContent}>
          <div className="col-sm-12 col-md-10 col-lg-10 ">
            <h3>OBJETIVOS INSTITUCIONAIS</h3>
            <article>
              <p>
                  Colaborar para a garantia de Direitos Humanos à adolescentes e
                  mulheres em situação de vulnerabilidade social, desenvolvendo
                  espaços educativos de acolhimento e encaminhamento à redes de
                  proteção e inclusão para o público;
              </p>
              <p>
                  Assegurar ao público espaços de compartilhamento de autocuidado,
                  prazer, bem estar, responsabilidade, projeção de futuro e conhecimento
                  sobre os seus direitos e deveres;
              </p>
              <p>
                  Facilitar a compreensão dos mecanismos das opressões vivenciadas
                  visando a tomada ou criação de novos papéis sociais e de
                  enfrentamento;
              </p>
              <p>
                  Ampliar a rede de relacionamento com instituições públicas e
                  privadas para o estabelecimento de ações de colaboração;
              </p>
              <p>
                  Realizar o intercâmbio de experiências e estabelecer ações com
                  outros grupos ativistas e organizações sociais na defesa de direitos de
                  adolescentes, mulheres e grupos LBTs;
              </p>
              <p>
                  Sistematizar e disseminar conhecimentos advindos de nossas práticas
                  como ato educativo, pedagógico e a denúncia de violação de direitos
                  sofridos por meninas adolescentes e mulheres em vulnerabilidade.
              </p>
            </article>
          </div>
        </motion.div>

        <motion.div className="d-flex flex-row justify-content-center" variants={variantsContent}>
          <div className="col-sm-12 col-md-10 col-lg-10 ">
            <h3>METAS</h3>
            <article>
              <p>
                Fortalecer nosso grupo e público em trocas de saberes e resistência em ações práticas na comunidade
                em geral – com foco onde se encontram mulheres e meninas vulnerabilizadas pela estrutura racial, sexista e capitalista,
                pois entendemos que mulheres e meninas sofrem as maiores e mais abrangentes violências e privações de direitos;
              </p>
              <p>
                Servir como exemplo, direção e inspiração, além de contribuir para que outros grupos se fortaleçam em suas práticas, 
                ormando uma efetiva rede de militantes sociais trabalhando em colaboração no alcance de uma sociedade mais justa.
              </p>
            </article>
          </div>
        </motion.div>

        <motion.div className="d-flex flex-row justify-content-center" variants={variantsContent}>
          <div className="col-sm-12 col-md-10 col-lg-10 ">
            <h3>VISÃO</h3>
            <article>
              <p>
                A libertação das correntes do colonialismo ao nosso entender significa sermos revolucionárias, capazes de escrevermos nossa própria história, educar para transformar, criar redes para proteger e ser teia para amortecer as quedas.
              </p>
            </article>
          </div>
        </motion.div>

        <motion.div className="d-flex flex-row justify-content-center" variants={variantsContent}>
          <div className="col-sm-12 col-md-10 col-lg-10 ">
            <h3>MISSÃO</h3>
            <article>
              <p>
                Promover mecanismos para a emancipação de meninas e mulheres das violências e das explorações vividas em decorrência das desigualdades raciais e socioeconômicas estabelecidas, fortalecendo uma vida compreendida como livre da negligência, opressão e violências, tendo como ferramentas a educação e a rede de acolhimento.
              </p>
              <p>
                Servir como exemplo, direção e inspiração, além de contribuir para que outros grupos se fortaleçam em suas práticas, 
                ormando uma efetiva rede de militantes sociais trabalhando em colaboração no alcance de uma sociedade mais justa.
              </p>
            </article>
          </div>
        </motion.div>

        <motion.div className="d-flex flex-row justify-content-center mb-5" variants={variantsContent} >
          <div className="col-sm-12 col-md-10 col-lg-10 ">
            <h3>VALORES</h3>
            <h5>TRABALHO DE BASE</h5>
            <article>
              <p>
                O Trabalho de Base é nossa ação social estruturante. 
                Através da escuta atenta e da construção de conhecimento e compreensão da realidade social de nosso público, 
                buscamos desenvolver estratégias para romper o silêncio em ciclos de naturalização de violências e contribuir para a 
                promoção de Direitos Humanos.
              </p>
            </article>  
            <h5>EDUCAÇÃO POPULAR</h5>
            <article>
              <p>
                Temos a Educação Popular como eixo metodológico formativo para a criação de espaços autônomos, abertos, participativos e de reflexões coletivas capazes de superar preconceitos.
              </p>
            </article>  
            <h5>INTERSECCIONALIDADE</h5>
            <article>
              <p>
                Temos a Educação Popular como eixo metodológico formativo para a criação de espaços autônomos, abertos, participativos e de reflexões coletivas capazes de superar preconceitos.
              </p>
            </article>  
            <h5>AUTOGESTÃO</h5>
            <article>
              <p>
                Temos a autogestão, a decisão coletiva e a inexistência de hierarquias como práticas fundamentais de nossa Organização. Entendemos que tal formato nos possibilita o exercício criativo e responsável de nossas atividades.
              </p>
            </article>              
          </div>
        </motion.div>
      </motion.div>
    </div>
  
  );
}

export default About;
